<template>
  <div class="container-fluid">
    <my-spinner :load="fetching"/>
    <div class="row justify-content-center mt-4" v-if="!fetching">
      <div class="col-12 bg-gray-1 py-5">
        <h3 class="rounded bg-primary text-center text-white mt-2">Administración de Residuos</h3>
        <div class="container-fluid">
            <!--BOTONERA  -->
          <template v-if="!locked">
            <a-row type="flex" justify="center" class="mt-4" v-show="titemCart.length > 0 && Object.keys(mixtures).length === 0">
              <b-button pill variant="secndary" @click="OpenMixtureModal()" v-show="titemCart.length > 0">
               <div class="d-none d-md-block" v-b-tooltip.hover title="Agregar Mezcla">
                 <b-icon icon="plus"/> Agregar Mezcla
               </div>
               <div class="d-md-none" v-b-tooltip.hover title="Agregar Mezcla">
                 <b-icon icon="plus"/>
               </div>
              </b-button>
            </a-row>
          </template>
          <a-row type="flex" justify="center" class="mt-4" v-if="Object.keys(mixtures).length > 0 && !locked">
              <b-button pill variant="primary" @click="OpenStepModal()" >
              <div class="d-none d-md-block" v-b-tooltip.hover title="Guardar">
                <b-icon icon="archive"/> Guardar
              </div>
              <div class="d-md-none" v-b-tooltip.hover title="Guardar">
                <b-icon icon="archive"/>
              </div>
              </b-button>
              <b-modal  ref="modalStep" size="xl" @hide="CloseStepModal">
                <a-form-model ref="formStep" layout="horizontal"
                    :model="updateStepObj.form" :rules="updateStepRules">
                  <!--TEXT - Responsable-->
                  <a-form-model-item label="Responsable" ref="waste_responsible"  prop="waste_responsible">
                      <a-input v-model="updateStepObj.form.waste_responsible" placeholder="Responsable" size="large"/>
                  </a-form-model-item>
                  <!--TEXT -Responsable -->
                  <!-- Botones -->
                  <a-row type="flex" justify="end" v-show="!spinner">
                    <a-col>
                      <b-button pill variant="primary" @click="UpdateStep">
                      <div class="d-none d-md-block" v-b-tooltip.hover title="Guardar">
                        <b-icon icon="archive"/> Guardar
                      </div>
                      <div class="d-md-none" v-b-tooltip.hover title="Guardar">
                        <b-icon icon="archive"/>
                      </div>
                      </b-button>
                    </a-col>
                  </a-row>
                  <!-- Botones -->
                </a-form-model>
                <template v-slot:modal-footer>
                  <simple-modal-footer @ClickEvent="CloseStepModal"/>
                </template>
              </b-modal>
          </a-row>
          <b-modal title="Registrar de Mezcla" ref="modalMixture" size="xl" @hide="CloseMixtureModal">
            <a-form-model ref="formMixture" layout="horizontal" :model="mixtureObj.form" :rules="mixtureRules">
              <a-row align="middle">
                <a-col :sm="{ span: 24 }" :md="{ span: 12 }">
                  <!--TEXT - Código de la Mezcla-->
                  <a-form-model-item label="Código de la Mezcla" ref="code"  prop="code" >
                     <a-input v-model="mixtureObj.form.code" placeholder="Código de la Mezcla" size="large"/>
                  </a-form-model-item>
                  <!--TEXT -Código de la Mexcla -->
                </a-col>
                <a-col :sm="{ span: 24 }" :md="{ span: 12 }">
                  <!--TEXT - Nombre de la Mezcla-->
                  <a-form-model-item label="Nombre de la Mezcla" ref="code"  prop="code" >
                     <a-input v-model="mixtureObj.form.name" placeholder="Nombre de la Mezcla" size="large"/>
                  </a-form-model-item>
                  <!--TEXT -Nombre de la Mezcla -->
                </a-col>
              </a-row>
              <a-row align="middle">
                <a-col :sm="{ span: 12 }" :md="{ span: 8 }">
                 <!-- SELECT Área de Producto terminado -->
                 <customDropdownSelect label="Área de Almacenaje" v-model="mixtureObj.form.finished_product_area_id"
                                       refAndPop="finished_product_area_id" resource="finishedProductAreas"/>
                 <!-- SELECT Área de Producto terminado -->
                </a-col>
                <a-col :sm="{ span: 6 }" :md="{ span: 8 }">
                  <!--TEXT - Fila-->
                  <a-form-model-item label="Fila" ref="row"  prop="row" >
                     <a-input v-model="mixtureObj.form.row" placeholder="Fila" size="large"/>
                  </a-form-model-item>
                  <!--TEXT -Fila -->
                </a-col>
                <a-col :sm="{ span: 6 }" :md="{ span: 8 }">
                  <!--TEXT - Cajon-->
                  <a-form-model-item label="Cajon" ref="drawer"  prop="drawer" >
                     <a-input v-model="mixtureObj.form.drawer" placeholder="Cajon" size="large"/>
                  </a-form-model-item>
                  <!--TEXT -Cajon -->
                </a-col>
              </a-row>
              <a-row align="middle">
                <a-col :sm="{ span: 12 }" :md="{ span: 8 }">
                  <!-- SELECT Corriente -->
                  <customDropdownSelect label="Corriente" v-model="mixtureObj.form.current_id"
                                      refAndPop="current_id" resource="currents"/>
                </a-col>
                <a-col :sm="{ span: 12 }" :md="{ span: 8 }">
                  <!--NUMBER INPUT - Total de Material Formulado-->
                  <a-form-model-item label="Total de Material Formulado" ref="total_formule_material"  prop="total_formule_material">
                     <a-input-number v-model="mixtureObj.form.total_formule_material"
                                      style="width:100%" :min="0" :step="0.1" size="large"/>
                  </a-form-model-item>
                  <!--NUMBER INPUT -Total de Material Formulado -->
                </a-col>
                <a-col :sm="{ span: 12 }" :md="{ span:8 }">
                  <!-- SELECT Unidad de Medida -->
                  <customDropdownSelect label="Unidad de Medida" v-model="mixtureObj.form.u_m_s_id"
                                        refAndPop="u_m_s_id" resource="ums"/>
                </a-col>
              </a-row>
              <a-row align="middle">
                <a-col :sm="{ span: 24 }" :md="{ span: 12 }">
                  <!--TEXT - Responsable-->
                  <a-form-model-item label="Responsable" ref="waste_responsible"  prop="waste_responsible">
                     <a-input v-model="mixtureObj.form.waste_responsible" placeholder="Responsable" size="large"/>
                  </a-form-model-item>
                  <!--TEXT -Responsable -->
                </a-col>
              </a-row>
              <!-- Botones -->
              <a-row type="flex" justify="end" v-show="!spinner">
                <a-col>
                  <b-button pill variant="primary" @click="SaveMixture">
                  <div class="d-none d-md-block" v-b-tooltip.hover title="Guardar">
                    <b-icon icon="archive"/> Guardar
                  </div>
                  <div class="d-md-none" v-b-tooltip.hover title="Guardar">
                    <b-icon icon="archive"/>
                  </div>
                  </b-button>
                </a-col>
              </a-row>
              <!-- Botones -->
            </a-form-model>
            <template v-slot:modal-footer>
              <simple-modal-footer @ClickEvent="CloseMixtureModal"/>
            </template>
          </b-modal>
          <div class="row justify-content-end mb-4" v-if="!locked">
            <transactionCart type="Salida" @deletedItem="FetchAvailableTable()"/>
          </div>
          <a-row type="flex" justify="end" class="mt-2" v-if="Object.keys(mixtures).length && titemCart.length && !locked">
            <a-col :span="5">
              <b-button pill variant="secondary" @click="AddNewTitems2Mixture()">
                <div class="d-none d-md-block" v-b-tooltip.hover title="Agregar residuos del carrito a la mezcla">
                  <b-icon icon="plus"/> Agregar residuos del carrito a la mezcla
                </div>
                <div class="d-md-none" v-b-tooltip.hover title="Agregar residuos del carrito a la mezcla">
                  <b-icon icon="plus"/>
                </div>
              </b-button>
            </a-col>
          </a-row>
            <!--BOTONERA  -->
          <div class="row justify-content-center mt-4">
            <div class="col-md-12 col-lg-6 my-2 py-1 bg-gray-3">
              <b-button class="d-block d-lg-none" block v-b-toggle.collapse-1 variant="secondary" pill>Master Residuos</b-button>
              <h5 class="d-none d-lg-block rounded bg-secondary text-center text-white mt-2 py-1">Master de Residuos</h5>
              <b-collapse id="collapse-1" visible>
                <!--// Filtros-->
                <a-row class="my-2" align="middle" :gutter="[8,24]">
                  <a-col :span="24">
                    <!--TEXT - -->
                    <a-input-search v-model="filterCode"
                                    placeholder="Buscar por elemento"
                                    enter-button
                                    @search="FetchAvailableTable" />
                    <!--TEXT - -->
                  </a-col>
                  <a-col :sm="24" :lg="12">
                    <!-- Todo descomentar cuando el recurso exista -->
                    <customDropdownSelect label="Almacén" v-model="filterWarehouses" resource="warehouses"
                                          :filters="warehouseFilters" orderField="code"
                                          placeholder="Seleccionar Almacén"/>
                  </a-col>
                  <a-col :sm="24" :lg="12">
                    <!-- SELECT Material -->
                    <customDropdownSelect label="Material" v-model="filterMaterial"
                                          orderField="name" resource="materials"
                                          placeholder="Seleccionar Material" optionValue="code"/>
                  </a-col>
                </a-row>
                <!--// Filtros-->
                <tdetailTitemsTable ref="tdetailTitemsTable" @formatTable="FormatAvailableTable($event)"
                                    :params="titemParam" style="width: 100%"/>
              </b-collapse>
            </div>
            <div class="col-md-12 col-lg-6 my-2 py-1 bg-white">
              <b-button class="d-block d-lg-none" block v-b-toggle.collapse-2 variant="info" pill>
                Mezcla
              </b-button>
              <h5 class="d-none d-lg-block rounded bg-info text-center text-white mt-2 py-1">Residuos Asignados</h5>
              <b-collapse id="collapse-2" visible>
                <a-descriptions title="Información General" bordered size="small"
                                v-if="Object.keys(mixtures).length > 0"
                                :column="{ xs: 1, sm: 1, md: 2 }">
                  <a-descriptions-item label="Código de Mezcla">
                    <strong>{{mixtures.code}}</strong>
                  </a-descriptions-item>
                  <a-descriptions-item label="Nombre de Mezcla">
                    <strong>{{mixtures.name}}</strong>
                  </a-descriptions-item>
                  <a-descriptions-item label="Corriente">
                    <strong v-if="mixtures.currents">{{mixtures.currents.name}}</strong>
                  </a-descriptions-item>
                  <a-descriptions-item label="Área de Almacenamiento">
                    <strong v-if="mixtures.finished_product_areas">{{mixtures.finished_product_areas.name}}</strong>
                  </a-descriptions-item>
                  <a-descriptions-item label="Fila">
                    <strong>{{mixtures.row}}</strong>
                  </a-descriptions-item>
                  <a-descriptions-item label="Cajon">
                    <strong>{{mixtures.drawer}}</strong>
                  </a-descriptions-item>
                  <a-descriptions-item label="Total de Material Formulado">
                    <strong>{{mixtures.total_formule_material}} - {{mixtures.ums.name}}</strong>
                  </a-descriptions-item>
                  <a-descriptions-item label="Acciones" v-if="!locked">
                    <b-button pill variant="danger" @click="DeleteMixture(mixtures.id)">
                     <div class="d-none d-md-block" v-b-tooltip.hover title="Eliminar">
                       <b-icon icon="trash"/> Eliminar
                     </div>
                     <div class="d-md-none" v-b-tooltip.hover title="Eliminar">
                       <b-icon icon="trash"/>
                     </div>
                    </b-button>
                    <b-button pill variant="secondary" @click="OpenMixtureModal(mixtures)">
                     <div class="d-none d-md-block" v-b-tooltip.hover title="Editar">
                       <b-icon icon="pencil-square"/> Editar
                     </div>
                     <div class="d-md-none" v-b-tooltip.hover title="Editar">
                       <b-icon icon="pencil-square"/>
                     </div>
                    </b-button>
                  </a-descriptions-item>
                </a-descriptions>
                <hr>
                <!--TABLA Titems-->
                   <a-table v-if="Object.keys(mixtures).length > 0"
                        bordered :columns="mixturesTitemsColumns"
                       :data-source="mixtures.mixture_items" :row-key="record => record.id">
                       <template slot="details" slot-scope="row">
                         <a-descriptions :title="`${row.t_items.consecutive} - ${row.t_items.code}`" bordered size="small" :column="{xs: 1, sm: 1, lg:2} ">
                           <a-descriptions-item label="Transacción">
                              <strong>{{row.t_items.t_details[0].transactions.code}}</strong>
                            </a-descriptions-item>
                            <a-descriptions-item label="Residuo">
                              <strong>{{row.t_items.t_details[0].sap_material_contracts.materials.name}}</strong>
                            </a-descriptions-item>
                            <a-descriptions-item label="Cantidad">
                              <strong>{{row.t_items.quantity}}</strong>
                            </a-descriptions-item>
                            <a-descriptions-item label="Contenedor">
                              <strong>{{row.t_items.t_details[0].container_types.name}}</strong>
                            </a-descriptions-item>
                            <a-descriptions-item label="Almacén">
                              <strong>{{row.t_items.t_details[0].transactions.warehouses.name}}</strong>
                            </a-descriptions-item>
                            <a-descriptions-item label="Acciones" v-if="!locked">
                              <b-button pill variant="danger" @click="DeleteTitemFromMixture(row.id)">
                                <div class="d-none d-md-block" v-b-tooltip.hover title="Eliminar">
                                  <b-icon icon="trash"/> Eliminar
                                </div>
                                <div class="d-md-none" v-b-tooltip.hover title="Eliminar">
                                  <b-icon icon="trash"/>
                                </div>
                              </b-button>
                            </a-descriptions-item>
                          </a-descriptions>
                       </template>
                   </a-table>
              </b-collapse>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { fractalMixin } from '@/mixins/fractalMixin'
import { apiV2Mixin } from '@/mixins/ApiV2Mixin'
import tdetailTitemsTable from '../../../warehouses/components/tables/tdetailTitemsTable'
import transactionCart from '../../../warehouses/components/transactionCart'
import { titemsStatusConstant } from '@/constant/titems'
import { Modal } from 'ant-design-vue'
import moment from 'moment'
const mixtureForm = {
  formulation_id: null,
  code: '',
  name: '',
  finished_product_area_id: null,
  row: '',
  drawer: '',
  current_id: null,
  total_formule_material: 1,
  u_m_s_id: null,
  is_blocked: true,
  waste_dated_at: '',
  waste_responsible: '',
  waste_status: 'Concluido',
  waste_block: false,
  storage_area_finished_product: 'No Aplica',
  t_items: [],
}
const UpdateStep = {
  waste_dated_at: '',
  waste_responsible: '',
  waste_status: 'Concluido',
  waste_block: true,
}
export default {
  name: 'materialTab',
  mixins: [fractalMixin, apiV2Mixin],
  props: {
    formulationId: {
      type: [String, Number],
      required: false,
    },
  },
  components: {
    tdetailTitemsTable,
    transactionCart,
  },
  data() {
    return {
      // constantes
      titemsStatus: titemsStatusConstant,
      // filtro de tabla de residuos disponibles
      titemParams: {
        include: 'wpositions',
        'filter[outputsAndTransfers]': '',
      },
      // filtro de tabla de residuos asignados
      formulationTitems: {
        include: 'wpositions',
        'filter[formulations]': '0',
      },
      // variables para los filtros selects
      filterWarehouses: undefined,
      filterMaterial: undefined,
      filterCode: '',
      // variables para los filtros selects
      locked: false,
      // resource
      formulation: {},
      fetching: true,
      // mixture
      // default forms structures
      mixtureDefaultForm: JSON.stringify(mixtureForm),
      defaultStepForm: JSON.stringify(UpdateStep),
      mixtureObj: {
        resourceType: 'mixtures',
        form: {},
      },
      mixtureRules: {
        code: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        name: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        finished_product_area_id: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        row: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        drawer: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        current_id: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        total_formule_material: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        u_m_s_id: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        waste_responsible: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
      },
      spinner: false,
      mixtures: {},
      mixturesTitemsColumns: [
        {
          title: 'Información General',
          key: 'detail',
          class: 'small text-center',
          scopedSlots: {
            customRender: 'details',
          },
        },
      ],
      // update step
      updateStepObj: {
        resourceType: `stepFormulations/${this.formulationId}`,
        form: {},
      },
      updateStepRules: {
        waste_responsible: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
      },
    }
  },
  watch: {
    async filterWarehouses(newValue) {
      this.titemParams['filter[warehouses]'] = newValue
      await this.FetchAvailableTable()
    },
    async filterMaterial(newValue) {
      this.titemParams['filter[materials]'] = newValue
      await this.FetchAvailableTable()
    },
  },
  computed: {
    ...mapState('warehouse', ['titemCart']),
    ...mapGetters('warehouse', ['titemCartIds']),
    titemParam() {
      return this.titemParams
    },
    warehouseFilters() {
      return this.formulation.work_center_id ? { workCenterId: this.formulation.work_center_id } : {}
    },
  },
  methods: {
    ...mapActions('warehouse', ['STORE_TITEM', 'DELETE_TITEM', 'RESET_LOCATIONS']),
    // Funciones para tabla de residuos disponibles
    FormatAvailableTable(table) {
      if (table.data.length > 0) {
        table.loading = true
        /*
          Limpiar resultados, es decir mostar lo que:
          -- 1.-NO esten en el estado de cart
        */
        table.data = table.data.filter((titem) => {
          // -----------------  1 -------------
          return !this.titemCartIds.includes(titem.code)
        })
      }
      table.loading = false
    },
    FetchAvailableTable() {
      if (this.filterCode) {
        this.titemParams['filter[likeCode]'] = this.filterCode
      } else if (!this.filterCode && Object.prototype.hasOwnProperty.call(this.titemParams, 'filter[likeCode]')) {
        delete this.titemParams['filter[likeCode]']
      }
      this.$refs.tdetailTitemsTable.Fetch()
    },
    // Funciones para tabla de residuos asignados
    FetchAssignedTable() {
      this.$refs.formulationTitems.Fetch()
    },
    // fetch of formulation
    async FetchResource() {
      const url = `formulations/${this.formulationId}`
      this.fetching = true
      const order = await this.AxiosGetv2(url, {})
      if (order) {
        this.formulation.id = order.data.id
        this.formulation.code = order.data.code
        this.formulation.operation_shift_id = order.data.operation_shift_id
        this.formulation.work_center_id = order.data.work_center_id
        this.formulation.formulation_type_id = order.data.formulation_type_id
        this.formulation.process_type_id = order.data.process_type_id
        this.formulation.operation_technical_1 = order.data.operation_technical_1
        this.formulation.operation_technical_2 = order.data.operation_technical_2
        this.formulation.worked_hours = order.data.worked_hours
        this.formulation.started_at = order.data.started_at
        this.formulation.finished_at = order.data.finished_at
        this.formulation.status = order.data.status
        this.formulation.deleted_at = order.data.deleted_at
        this.formulation.created_at = order.data.created_at
        this.formulation.updated_at = order.data.updated_at
        this.formulation.operation_shifts = order.data.operation_shifts
        this.formulation.work_centers = order.data.work_centers
        this.formulation.formulation_types = order.data.formulation
        this.formulation.process_types = order.data.process_types
        this.formulation.step_formulations = order.data.step_formulations
        const block = order.data.step_formulations.waste_block
        console.log(block)
        switch (block) {
          case '0':
          case 0:
          case '':
          case false:
            this.locked = false
            break
          case 1:
          case '1':
          case true:
            this.locked = true
            break
          default:
            this.locked = false
            break
        }
      }
      this.fetching = false
    },
    // Save Mixture
    OpenMixtureModal(data = {}) {
      this.spinner = false
      this.mixtureObj.form = JSON.parse(this.mixtureDefaultForm)
      this.mixtureObj.form.formulation_id = this.formulationId
      if (Object.keys(data).length > 0) {
        // Asignar obj a formulario
        this.mixtureObj.resourceType = `mixtures/${data.id}`
        this.mixtureObj.form.code = data.code
        this.mixtureObj.form.name = data.name
        this.mixtureObj.form.finished_product_area_id = parseInt(data.finished_product_area_id)
        this.mixtureObj.form.row = data.row
        this.mixtureObj.form.drawer = data.drawer
        this.mixtureObj.form.current_id = parseInt(data.current_id)
        this.mixtureObj.form.total_formule_material = data.total_formule_material
        this.mixtureObj.form.u_m_s_id = parseInt(data.u_m_s_id)
        this.mixtureObj.form.is_blocked = data.is_blocked
        this.mixtureObj.form.waste_dated_at = data.waste_dated_at
        this.mixtureObj.form.waste_responsible = data.waste_responsible
        this.mixtureObj.form.waste_status = data.waste_status
        this.mixtureObj.form.waste_block = data.waste_block
        this.mixtureObj.form.storage_area_finished_product = data.storage_area_finished_product
      }
      this.$refs.modalMixture.show()
    },
    CloseMixtureModal() {
      this.spinner = false
      this.$refs.modalMixture.hide()
      this.mixtureObj.form = JSON.parse(this.mixtureDefaultForm)
    },
    SaveMixture() {
      this.$refs.formMixture.validate(async(valid) => {
        if (valid) {
          this.spinner = true
          // validate post or put
          this.mixtureObj.form.waste_dated_at = moment().format('YYYY-MM-DD')
          if (this.mixtureObj.resourceType !== 'mixtures') {
            // put
            await this.PutResourcev2(this.mixtureObj, async () => {
              await this.CloseMixtureModal()
              this.RESET_LOCATIONS()
              this.spinner = false
              await this.FetchFormulationMixtures()
            })
          } else {
            this.mixtureObj.form.t_items = this.titemCart.map(titem => titem.id)
            await this.PostResourcev2(this.mixtureObj, async () => {
              await this.CloseMixtureModal()
              this.RESET_LOCATIONS()
              this.spinner = false
              await this.FetchFormulationMixtures()
            })
          }
          this.spinner = false
        } else return false
      })
    },
    async FetchFormulationMixtures() {
      const params = {
        page: 1,
        pageSize: 1,
        formulationId: this.formulationId,
      }
      const mixture = await this.AxiosGetv2('mixtures', params)
      if (mixture && mixture.data && mixture.data.data && mixture.data.data.length) {
        this.mixtures = mixture.data.data[0]
      } else this.mixtures = {}
    },
    DeleteMixture(id) {
      const me = this
      Modal.confirm({
        title: '¿Esta seguro de eliminar esta mezcla?',
        content: 'Esta operación es irreversible',
        okText: 'Sí',
        okType: 'danger',
        cancelText: 'No',
        zIndex: 3000,
        async onOk () {
          me.fetching = true
          const url = `mixtures/${id}`
          await me.AxiosDelete(url, {}, async () => {
            await me.FetchFormulationMixtures()
            me.fetching = false
          })
          me.fetching = false
        },
      })
    },
    OpenStepModal() {
      this.spinner = false
      this.updateStepObj.form = JSON.parse(this.defaultStepForm)
      this.$refs.modalStep.show()
    },
    CloseStepModal() {
      this.spinner = false
      this.updateStepObj.form = JSON.parse(this.defaultStepForm)
      this.$refs.modalStep.hide()
    },
    async UpdateStep() {
      const me = this
      this.$refs.formStep.validate(async(valid) => {
        if (valid) {
          Modal.confirm({
            title: '¿Esta seguro de concluir las operacione de residuos?',
            content: 'Esta operación es irreversible',
            okText: 'Sí',
            okType: 'danger',
            cancelText: 'No',
            zIndex: 3000,
            async onOk () {
              me.updateStepObj.form.waste_dated_at = moment().format('YYYY-MM-DD')
              me.spinner = true
              await me.PutResourcev2(me.updateStepObj, async () => {
                await me.CloseStepModal()
                me.spinner = false
                await me.FetchResource()
              })
            },
          })
        } else return false
      })
    },
    DeleteTitemFromMixture(id) {
      const me = this
      Modal.confirm({
        title: '¿Esta seguro de eliminar este residuo de la mezcla?',
        content: 'Esta operación es irreversible',
        okText: 'Sí',
        okType: 'danger',
        cancelText: 'No',
        zIndex: 3000,
        async onOk () {
          me.fetching = true
          const url = `mixtureTitems/${id}`
          await me.AxiosDelete(url, {}, async () => {
            await me.FetchFormulationMixtures()
            me.fetching = false
          })
          me.fetching = false
        },
      })
    },
    async AddNewTitems2Mixture() {
      this.fetching = true
      this.mixtureObj.form.t_items = this.titemCart.map(titem => titem.id)
      const formObj = {
        resourceType: 'mixtureTitems',
        form: {
          mixture_id: this.mixtures.id,
          t_items: this.titemCart.map(titem => titem.id),
        },
      }
      await this.PostResourcev2(formObj, async () => {
        this.fetching = false
        this.RESET_LOCATIONS()
        await this.FetchFormulationMixtures()
      })

      this.fetching = false
    },
  },
  async mounted() {
    this.RESET_LOCATIONS()
    await this.FetchResource()
    await this.FetchFormulationMixtures()
  },
}
</script>

<style scoped>

</style>
