<template>
  <div>
    <div class="row justify-content-end mb-4" v-if="withSelect">
      <a-button @click="SetStatus(titemsStatus.autorizado)">
        Autorizar Seleccionados
        <a-icon type="check-square" theme="twoTone" twoToneColor="#00A654"/>
      </a-button>
      <a-button @click="SetStatus(titemsStatus.rechazado)">
        Rechazar Seleccionados
        <a-icon type="exclamation-circle" theme="twoTone" twoToneColor="#ff0000"/>
      </a-button>
    </div>
    <!--TABLA-->
    <a-table
      :columns="tableObject.columns"
      :data-source="tableObject.data"
      :pagination="tableObject.pagination"
      :loading="tableObject.loading"
      @change="Fetch"
      :row-selection="withSelect ? { columnTitle: 'Seleccionar',columnWidth: '10%',
      selectedRows: selectedRows, onChange: onSelectChange, getCheckboxProps: getCheckboxProps } : null"
      :row-key="record => record.id" bordered>
      <div slot="details" slot-scope="row">
          <a-descriptions :title="`${row.consecutive} - ${row.code}`" bordered size="small" :column="{xs: 1, sm: 1, lg:2} ">
            <a-descriptions-item label="Transacción">
              <strong>{{row.last_tdetail.transactions.code}}</strong> - {{row.last_tdetail.transactions.warehouseTransactions}}
            </a-descriptions-item>
            <a-descriptions-item label="Almacén">
              <strong>{{row.last_tdetail.transactions.warehouses.name}}</strong>
            </a-descriptions-item>
            <a-descriptions-item label="Residuo">
              <strong>{{row.last_tdetail.materials.name}}</strong>
            </a-descriptions-item>
            <a-descriptions-item label="Cantidad">
              <strong>{{row.quantity}}</strong>
            </a-descriptions-item>
            <a-descriptions-item label="Contenedor">
              <strong>{{row.last_tdetail.containerTypes.name}}</strong>
            </a-descriptions-item>>
            <a-descriptions-item label="Antiguedad">
              <span>{{moment(row.antiquity).format('DD/MM/YY')}} </span>
              |
              <span v-if="moment().diff(moment(row.antiquity), 'days') > 1">
              hace {{ moment().diff(moment(row.antiquity), 'days') }} días
            </span>
              <span v-else>{{moment(row.antiquity).fromNow()}}</span>
              &nbsp;
              <a-icon v-if="moment().diff(moment(row.antiquity), 'days') > 150"
                      type="warning" theme="twoTone" two-tone-color="#f2a654"/>
              <a-icon type="like" theme="twoTone" two-tone-color="#00a654" v-else/>
            </a-descriptions-item>
            <a-descriptions-item label="Posición">
              <strong v-if="row.wpositions">{{row.wpositions.name}}  </strong>
              <strong v-else>NA</strong>
            </a-descriptions-item>
            <a-descriptions-item label="Estatus">
              <titemStatus :status="row.status"/>
            </a-descriptions-item>
          </a-descriptions>
      </div>
      <div slot="action" slot-scope="row" >
        <slot name="action_btns" :row="row">
          <a-button v-if="row.status === titemsStatus.autorizado" @click="AddItem(row)">
            <small>#{{row.consecutive}}</small>
            <a-icon type="plus-square" theme="twoTone"/>
          </a-button>
        </slot>
      </div>
    </a-table>
    <!--TABLA-->
  </div>
</template>

<script>
import { Modal } from 'ant-design-vue'
import { fractalMixin } from '@/mixins/fractalMixin'
import { mapActions, mapState, mapGetters } from 'vuex'
import titemStatus from '../../components/titemStatus'
// import titemsResume from '../titemsResume'
import { titemsStatusConstant } from '@/constant/titems'
export default {
  name: 'tdetailTitemsTable',
  mixins: [fractalMixin],
  components: {
    // titemsResume,
    titemStatus,
  },
  props: {
    params: {
      required: true,
      type: Object,
    },
    withSelect: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    params: 'Fetch',
  },
  data() {
    return {
      titemsStatus: titemsStatusConstant,
      tableObject: {
        data: [],
        pagination: {
          current: 1,
          pageSize: 12,
          from: 0,
          to: 0,
          total: 0,
          lastPage: 0,
        },
        loading: false,
        columns: [
          {
            title: 'Información General',
            key: 'detail',
            class: 'small text-center',
            scopedSlots: {
              customRender: 'details',
            },
            // width: '82%',
          },
          {
            title: 'Agregar',
            key: 'x',
            class: 'small text-center',
            scopedSlots: { customRender: 'action' },
            width: '12%',
          },
        ],
      },
      selectedRows: [],
    }
  },
  computed: {
    ...mapState('warehouse', ['titemCart']),
    ...mapGetters('warehouse', ['titemCartIds']),
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
        },
        getCheckboxProps: record => ({
          props: {
            disabled: record.status !== 'Autorizado', // Column configuration not to be checked
          },
        }),
      }
    },
  },
  methods: {
    ...mapActions('warehouse', ['STORE_TITEM']),
    async Fetch(pageEvent = {}) {
      await this.GetTableResource(pageEvent, 'titems', this.tableObject, this.params)
      this.FormatTable()
    },
    async AddItem(item) {
      this.STORE_TITEM(item)
      await this.FormatTable()
    },
    async SetStatus(status) {
      const me = this
      Modal.confirm({
        title: '¿Desea cambiar el estatus del elemento?',
        content: 'Esta operación es irreversible',
        okText: 'Sí',
        okType: 'danger',
        cancelText: 'No',
        zIndex: 3000,
        async onOk () {
          await me.AxiosPost('/setTitemStatus', {
            status: status,
            titems: me.selectedRows,
          }, async() => {
            me.selectedRows = []
            await me.Fetch()
          })
        },
        onCancel () {
        },
      })
    },
    FormatTable() {
      this.$emit('formatTable', this.tableObject)
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRows = selectedRows
    },
    // getCheckboxProps: record => ({
    //   props: {
    //     disabled: record.status !== 'Pendiente', // Column configuration not to be checked
    //   },
    // }),
    getCheckboxProps(record) {
      return ({
        props: {
          disabled: record.status !== this.titemsStatus.pendiente, // Column configuration not to be checked
        },
      })
    },
  },
  async mounted() {
    if (this.params) {
      await this.Fetch()
    }
  },
}
</script>

<style scoped>

</style>
