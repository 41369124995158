<template>
<div class="row justify-content-end mr-2">
  <!--BOTONERA  -->
  <b-button-group>
    <b-button  variant="success" pill @click="OpenModal()">
      <div class="d-none d-lg-block" v-b-tooltip.hover :title="text">
        <b-icon icon="cart3"/>
        {{ text }}
      </div>
      <div class="d-lg-none" v-b-tooltip.hover :title="text">
        <b-icon icon="cart3"/>
      </div>
    </b-button>
  </b-button-group>
  <!--BOTONERA  -->
  <!-- MODAL -->
  <b-modal title="Residuos agregados" ref="detailCart" size="lg" @hide="CloseModal" >
    <b-container fluid>
      <a-list item-layout="horizontal" bordered
              v-if="type === 'Entrada'" :data-source="smcCart">
        <a-list-item slot="renderItem" slot-scope="item">
          <a-list-item-meta :description="item.sapMaterialContracts.materialName">
            <div class="row" slot="title">
              <div class="col-8">
                <span class="font-weight-bold">Contenedores: </span>
                {{ item.container_quantity }} {{ item.containerTypes.name }}
              </div>
              <div class="col-8">
                <span class="font-weight-bold">Cantidad Real: </span>
                {{ item.real_quantity }} {{ item.ums.name }}
              </div>
            </div>
            <a-button slot="avatar" @click="deleteItemCart(item)">
              <a-icon type="delete" theme="twoTone" two-tone-color="#FF0000"/>
            </a-button>
          </a-list-item-meta>
        </a-list-item>
      </a-list>
      <titemsResume v-else :items="titemCart">
        <a-button slot="btn-icon" slot-scope="{item}"  @click="deleteItemCart(item)">
          #{{item.consecutive}}
          <a-icon type="delete" theme="twoTone" two-tone-color="#ff0000"/>
        </a-button>
      </titemsResume>
    </b-container>
    <template v-slot:modal-footer>
      <simple-modal-footer @ClickEvent="CloseModal"/>
    </template>
  </b-modal>
  <!-- MODAL -->
</div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import titemsResume from '../components/titemsResume'
export default {
  name: 'transactionCart',
  components: {
    titemsResume,
  },
  props: {
    type: {
      required: true,
      type: String,
      validator: function (value) {
        // El valor debe coincidir con una de estas cadenas de texto
        return ['Entrada', 'Salida', 'Traspaso'].indexOf(value) !== -1
      },
    },
  },
  computed: {
    ...mapState('warehouse', ['smcCart', 'titemCart']),
    ...mapGetters('warehouse', ['smcCartItems', 'titemCartItems']),
    text() {
      if (this.type === 'Entrada') {
        return `${this.smcCartItems}`
      } else return `${this.titemCartItems}`
    },
  },
  methods: {
    ...mapActions('warehouse', ['STORE_SMC', 'DELETE_SMC', 'STORE_TITEM', 'DELETE_TITEM', 'CLEAN_STATE']),
    OpenModal() {
      this.$refs.detailCart.show()
    },
    CloseModal() {
      this.$refs.detailCart.hide()
    },
    deleteItemCart(data) {
      this.$emit('deletedItem')
      // Delete data to vuex
      if (this.type === 'Entrada') {
        this.DELETE_SMC(data)
      } else this.DELETE_TITEM(data)
    },
  },
}
</script>

<style scoped>

</style>
